import React from 'react';
import { api } from "api";
import validate from './validation';
import { Link } from 'react-router-dom';
import { departamentos } from 'variables';
import { Field, FieldArray, reduxForm, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';

import {
    renderTextArea,
    modalTextArea,
    renderField,
    renderDatePicker,
    renderSearchSelect,
    renderSelectCustom,
    renderSelectCustomSmall,
    renderSearchSelectPatient,
    renderSwitch
} from 'Utils/renderField/renderField';
import './surgery.css';
import MyModal from '../Utils/myModal';
import ServiceForm from './servicesForm';

let persons = [];
let patients = [];
let referents = [];

const getReferents =  async (search) => {
    try{
    const response = await api.get(`configuration/getConfiguration`);
    const default_financial_entity = response.default_financial_entity;

    const data = await api.get("referents/getReferentsWithEntity/", {search});

    if (data){
        referents = [];
        data.forEach(x=>{
                let idFinancialEntity = 0;
                let usuarioFel = "";
                let idReferentCategory = 0;
                let receipt = false;

                if(x.institution !== null){
                    idFinancialEntity = x.institution.referent_category.financial_entity.id;
                    usuarioFel = x.institution.referent_category.financial_entity.usuario_fel;
                    idReferentCategory = x.institution.referent_category.id;
                    receipt = x.institution.referent_category.issue_receipt;
                }else{
                    idFinancialEntity = default_financial_entity.id;
                    usuarioFel = default_financial_entity.usuario_fel;
                    idReferentCategory = 0;
                    receipt = default_financial_entity.afiliacion_fel === "exento" ? true : false;
                }

                referents.push(
                    {
                        value: x.id,
                        label: x.name,
                        usuario_fel: usuarioFel,
                        financial_entity: idFinancialEntity,
                        idReferentCategory: idReferentCategory,
                        receipt
                    }
                )
        });
        return referents;
    }else{
        return [];
    }

    }catch(error){
        console.error(error);
    }
}

const getPersons = (search) => {
    return api.get("referents/listDoctors", {search}).catch((error) => {})
        .then((data) => {
            if (data){
                persons = [];
                data.results.forEach(x=>{
                    persons.push({value: x.id, label: x.name})
                })
                return persons
            }
            else{
                return []
            }
        })
        .catch(e => {
            return []
        })
}

const getPatients = (search) => {
    return api.get("patients", {search}).catch((error) => {})
        .then((data) => {
            if (data){
                patients = [];
                data.results.forEach(x=>{
                    patients.push({value: x.id, label: x.first_name + " " + x.last_name})
                })
                return patients
            }
            else{
                return []
            }
        })
        .catch(e => {
            return []
        })
}

const Form = props => {
    const {
        municipios,
        handleSubmit,
        search_patient,
        showPatientSearch,
        no_patient_options,
        showPatientOptions,
        detailPatient,
        setUpdateData,
        initializePatientBirthdate,

        showProducts,
        load_products,
        services_list,

        addServiceToListDiff,
        start,
    } = props;

    if(start !== undefined && start !== null){
        const end_value = start.value + 0.5;
        const end_label = end_value % 1 === 0 ? end_value.toString() + ':00' : start.value.toString() + ':30';
        props.change('end', {value: end_value, label: end_label});
    }

    const view = window.location.href.includes("view");

    return(
        <form onSubmit={handleSubmit} className="pb-3 pt-5 d-flex justify-content-center">
            <div className="form-body-width">
                {/*
                <div className="d-flex justify-content-between flex-column flex-sm-row align-items-stretch align-items-sm-center">

                    <div className="m-2">
                        <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                            <label htmlFor="appointment_holder" className="">Nombre de quien agenda</label>
                        </div>
                        <div className=" d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                            <Field name="appointment_holder" disabled={view} component={renderField} type="text" className="form-control"/>
                        </div>
                    </div>
                    <div className="m-2">
                        <div className=" d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                            <label htmlFor="patient_name" className="">Nombre del paciente</label>
                        </div>
                        <div className=" d-flex justify-content-end flex-column flex-sm-row align-items-stretch align-items-sm-center">
                            <Field name="patient_name" disabled={view} component={renderField} type="text" className="form-control"/>
                        </div>
                    </div>
                </div>

                <div className="row-fluid d-flex justify-content-between flex-column flex-sm-row align-items-stretch align-items-sm-center">
                    <div className="m-2">
                        <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                            <label htmlFor="phone_holder" className="">Teléfono</label>
                        </div>
                        <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                            <Field name="phone_holder" disabled={view} component={renderField} type="number" className="form-control"/>
                        </div>
                    </div>
                    <div className="m-2">
                        <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                            <label htmlFor="cellphone_holder" className="">Celular</label>
                        </div>
                        <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                            <Field name="cellphone_holder" disabled={view} component={renderField} type="number" className="form-control"/>
                        </div>
                    </div>
                </div>
                */}

                <div className="d-flex justify-content-between flex-column flex-sm-row align-items-stretch align-items-sm-center">
                    <div className="m-2">
                        <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                            <label htmlFor="doctor" className="">Paciente</label>
                        </div>
                        <div className=" d-flex justify-content-end flex-column flex-sm-row align-items-stretch align-items-sm-center">
                            <Field
                                name="patient"
                                valueKey="value"
                                labelKey="label"
                                label="name"
                                component={renderSearchSelect}
                                loadOptions={getPatients}
                                className="no-border"
                                autofocus
                            />
                        </div>
                    </div>
                    <div className="m-2">
                        <div className=" d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                            <label htmlFor="comodin" className="">Requerimientos</label>
                        </div>
                        <div className="d-flex justify-content-end flex-column flex-sm-row align-items-stretch align-items-sm-center">
                            <Field name="requirements" disabled={view} component={"input"} type="text" className="form-control" placeholder='Ej. debe venir en ayunas.' />
                        </div>
                    </div>
                </div>

                <div className="d-flex justify-content-between flex-column flex-sm-row align-items-stretch align-items-sm-center">
                    <div className="m-2">
                        <div className=" d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                            <label htmlFor="comodin" className="">Descripción</label>
                        </div>
                        <div className="d-flex justify-content-end flex-column flex-sm-row align-items-stretch align-items-sm-center">
                            <Field name="note" disabled={view} component={"input"} type="text" className="form-control" placeholder='' />
                        </div>
                    </div>
                </div>

                <div className="d-flex justify-content-between flex-column flex-sm-row align-items-stretch align-items-sm-center">
                    <div className="m-2">
                        <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                            <label htmlFor="doctor" className="">Médico Encargado</label>
                        </div>
                        <div className=" d-flex justify-content-end flex-column flex-sm-row align-items-stretch align-items-sm-center">
                            <Field
                                name="doctor"
                                valueKey="value"
                                labelKey="label"
                                label="name"
                                component={renderSearchSelect}
                                loadOptions={getPersons}
                                className="no-border"
                            />
                        </div>
                    </div>
                    <div className="m-2">
                        <div className=" d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                            <label htmlFor="created" className="">Fecha de creación</label>
                        </div>
                        <div className=" d-flex justify-content-end flex-column flex-sm-row align-items-stretch align-items-sm-center">
                            <Field name="created" disabled={true} component={renderField} type="text" className="form-control"/>
                        </div>
                    </div>
                </div>

                <div className="d-flex justify-content-between flex-column flex-sm-row align-items-stretch align-items-sm-center">
                    <div className="m-2">
                        <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                            <label htmlFor="referent" className="">Referente</label>
                        </div>
                        <div className="d-flex justify-content-end flex-column flex-sm-row align-items-stretch align-items-sm-center">
                            <Field
                                name="referent"
                                valueKey="value"
                                labelKey="label"
                                label="name"
                                component={renderSearchSelect}
                                loadOptions={getReferents}
                                className="no-border"
                            />
                        </div>
                    </div>
                    <div className="m-2">
                        <div className=" d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                            <label htmlFor="created" className="">Número de lente</label>
                        </div>
                        <div className=" d-flex justify-content-end flex-column flex-sm-row align-items-stretch align-items-sm-center">
                            <Field name="lenses_number" disabled={view} component={renderField} type="text" className="form-control"/>
                        </div>
                    </div>
                </div>


                <div className="row-fluid d-flex justify-content-between flex-column flex-sm-row align-items-stretch align-items-sm-center">
                    <div className="mx-2 mt-2 mb-4 w-100">
                        <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                            <label htmlFor="" className="mr-3">Fecha de cirugía</label>
                        </div>
                        <div className="d-flex justify-content-between flex-column flex-sm-row align-items-stretch align-items-sm-center">
                            <div className="mr-3">
                                <Field name="date" isDisabled={view} component={renderDatePicker} type="text"/>
                            </div>
                            <div className="d-flex flex-inline align-items-center">
                                <div className="">
                                    <Field name="start" disabled={view} component={renderSelectCustomSmall} options={[
                                        {value:7.0, label:'07:00'}, {value:7.5, label:'07:30'},
                                        {value:8.0, label:'08:00'}, {value:8.5, label:'08:30'},
                                        {value:9.0, label:'09:00'}, {value:9.5, label:'09:30'},

                                        {value:10.0, label:'10:00'}, {value:10.5, label:'10:30'},
                                        {value:11.0, label:'11:00'}, {value:11.5, label:'11:30'},
                                        {value:12.0, label:'12:00'}, {value:12.5, label:'12:30'},
                                        {value:13.0, label:'13:00'}, {value:13.5, label:'13:30'},
                                        {value:14.0, label:'14:00'}, {value:14.5, label:'14:30'},
                                        {value:15.0, label:'15:00'}, {value:15.5, label:'15:30'},
                                        {value:16.0, label:'16:00'}, {value:16.5, label:'16:30'},
                                        {value:17.0, label:'17:00'}, {value:17.5, label:'17:30'},
                                        {value:18.0, label:'18:00'}, {value:18.5, label:'18:30'},
                                    ]}/>
                                </div>
                                <div className="ml-2 mr-2">
                                    <label htmlFor="hour" className="m-auto switch-label">Hrs.</label>
                                </div>
                                <div className="mx-2">
                                    <label htmlFor="hour" className="m-auto switch-label">A</label>
                                </div>
                                <div className="ml-2">
                                    <Field name="end" disabled={view} component={renderSelectCustomSmall} options={[
                                        // {value:0.5, label:'00:30'},
                                        // {value:1.0, label:'01:00'}, {value:1.5, label:'01:30'},
                                        // {value:2.0, label:'02:00'}, {value:2.5, label:'02:30'},
                                        // {value:3.0, label:'03:00'}, {value:3.5, label:'03:30'},
                                        // {value:4.0, label:'04:00'}, {value:4.5, label:'04:30'},
                                        // {value:5.0, label:'05:00'}, {value:5.5, label:'05:30'},
                                        // {value:6.0, label:'06:00'}, {value:6.5, label:'06:30'},
                                        // {value:7.0, label:'07:00'},

                                        {value:7.5, label:'07:30'},
                                        {value:8.0, label:'08:00'}, {value:8.5, label:'08:30'},
                                        {value:9.0, label:'09:00'}, {value:9.5, label:'09:30'},

                                        {value:10.0, label:'10:00'}, {value:10.5, label:'10:30'},
                                        {value:11.0, label:'11:00'}, {value:11.5, label:'11:30'},
                                        {value:12.0, label:'12:00'}, {value:12.5, label:'12:30'},
                                        {value:13.0, label:'13:00'}, {value:13.5, label:'13:30'},
                                        {value:14.0, label:'14:00'}, {value:14.5, label:'14:30'},
                                        {value:15.0, label:'15:00'}, {value:15.5, label:'15:30'},
                                        {value:16.0, label:'16:00'}, {value:16.5, label:'16:30'},
                                        {value:17.0, label:'17:00'}, {value:17.5, label:'17:30'},
                                        {value:18.0, label:'18:00'}, {value:18.5, label:'18:30'},
                                        {value:19.0, label:'19:00'},

                                        // {value:19.5, label:'19:30'},
                                        // {value:20.0, label:'20:00'}, {value:20.5, label:'20:30'},
                                        // {value:21.0, label:'21:00'}, {value:21.5, label:'21:30'},
                                        // {value:22.0, label:'22:00'}, {value:22.5, label:'22:30'},
                                        // {value:23.0, label:'23:00'}, {value:23.5, label:'23:30'},
                                        // {value:24.0, label:'24:00'}
                                    ]}/>
                                </div>
                                <div className="ml-2">
                                    <label htmlFor="hour" className="m-auto switch-label">Hrs.</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*
                <div className="mt-3 mx-2 mb-4 row-fluid d-flex justify-content-center flex-column flex-sm-row align-items-stretch align-items-sm-center">
                    <div className="patient-label mr-md-3">
                        <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                            <label className="m-0">DATOS DEL PACIENTE</label>
                        </div>
                    </div>
                    <div className="line-patient mr-3 d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center w-100">

                    </div>
                    <div onClick={showPatientOptions} className={`${no_patient_options == true ? 'patient-up-icon' : 'patient-down-icon'} my-2`}>
                        <div></div>
                    </div>
                </div>

                    !no_patient_options &&
                        <React.Fragment>
                            {
                                search_patient ?
                                    <React.Fragment>
                                        <div className="px-md-5 mt-md-5 mb-md-4 row-fluid d-flex justify-content-around flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                            <div className="m-2">
                                                <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                                    <label htmlFor="person" className="">Buscar y seleccionar paciente</label>
                                                </div>
                                                <div className=" d-flex justify-content-end flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                                    <Field
                                                        name="petient_search"
                                                        valueKey="value"
                                                        labelKey="label"
                                                        label="name"
                                                        component={renderSearchSelectPatient}
                                                        loadOptions={getPatients}
                                                        className="no-border"
                                                        onChange={(e) => { showPatientSearch(); detailPatient(e.value);}}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="px-md-5 mb-md-5 row-fluid d-flex justify-content-around flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                            <div className="m-2">
                                                <div className="mb-md-5 d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                                    <div className="mx-4 line-price d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center w-100">

                                                    </div>
                                                    <label htmlFor="line" className="m-0">O</label>
                                                    <div className="mx-4 line-price d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center w-100">

                                                    </div>
                                                </div>
                                                <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                                    <button onClick={initializePatientBirthdate} className="btn btn-primary mb-md-2 search-patient-button">Crear paciente</button>
                                                </div>
                                            </div>
                                        </div>

                                    </React.Fragment>
                            :
                                <React.Fragment>
                                    <div className="d-flex justify-content-between flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                        <div className="m-2">
                                            <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                                <label htmlFor="first_name" className="">Nombre*</label>
                                            </div>
                                            <div className=" d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                                <Field name="first_name" disabled={view} component={renderField} type="text" className="form-control"/>
                                            </div>
                                        </div>
                                        <div className="m-2">
                                            <div className=" d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                                <label htmlFor="last_name" className="">Apellidos*</label>
                                            </div>
                                            <div className=" d-flex justify-content-end flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                                <Field name="last_name" disabled={view} component={renderField} type="text" className="form-control"/>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="d-flex justify-content-between flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                        <div className="m-2">
                                            <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                                <label htmlFor="married_last_name" className="">Apellido de casada</label>
                                            </div>
                                            <div className=" d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                                <Field name="married_last_name" disabled={view} component={renderField} type="text" className="form-control"/>
                                            </div>
                                        </div>
                                        <div className="m-2">
                                            <div className=" d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                                <label htmlFor="birthdate" className="">Fecha de nacimiento*</label>
                                            </div>
                                            <div className=" d-flex justify-content-end flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                                <Field name="birthdate" isDisabled={view} component={renderDatePicker} type="text" className="form-control" />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="d-flex justify-content-between flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                        <div className="m-2">
                                            <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                                <label htmlFor="gender" className="">Género*</label>
                                            </div>
                                            <div className=" d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                                <Field name="gender" disabled={view} component={renderSelectCustom} options={[
                                                    {value:1, label:'Masculino'},
                                                    {value:2, label:'Femenino'},
                                                ]}/>
                                            </div>
                                        </div>
                                        <div className="m-2">
                                            <div className=" d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                                <label htmlFor="nationality" className="">Nacionalidad*</label>
                                            </div>
                                            <div className=" d-flex justify-content-end flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                                <Field name="nationality" disabled={view} component={renderField} type="text" className="form-control"/>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="d-flex justify-content-between flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                        <div className="m-2">
                                            <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                                <label htmlFor="departamento" className="">Departamento</label>
                                            </div>
                                            <div className=" d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                                <Field name="departamento" disabled={view} component={renderSelectCustom} options={departamentos}/>
                                            </div>
                                        </div>
                                        <div className="m-2">
                                            <div className=" d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                                <label htmlFor="municipio" className="">Municipio</label>
                                            </div>
                                            <div className=" d-flex justify-content-end flex-column flex-sm-row align-items-stretch align-items-sm-center">

                                                <Field name="municipio" disabled={view} component={renderSelectCustom} options={municipios}/>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="d-flex justify-content-between flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                        <div className="m-2">
                                            <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                                <label htmlFor="address" className="">Dirección</label>
                                            </div>
                                            <div className=" d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                                <Field name="address" disabled={view} component={renderField} type="text" className="form-control"/>
                                            </div>
                                        </div>
                                        <div className="m-2">
                                            <div className=" d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                                <label htmlFor="dpi" className="">DPI</label>
                                            </div>
                                            <div className=" d-flex justify-content-end flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                                <Field name="dpi" disabled={view} component={renderField} type="text" className="form-control"/>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="d-flex justify-content-between flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                        <div className="m-2">
                                            <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                                <label htmlFor="profession" className="">Profesión</label>
                                            </div>
                                            <div className=" d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                                <Field name="profession" disabled={view} component={renderField} type="text" className="form-control"/>
                                            </div>
                                        </div>
                                        <div className="m-2">
                                            <div className=" d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                                <label htmlFor="family_member_of" className="">Médico o familiar de médico</label>
                                            </div>
                                            <div className=" d-flex justify-content-end flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                                <Field
                                                disabled={view}
                                                    name="family_member_of"
                                                    valueKey="value"
                                                    labelKey="label"
                                                    label="name"
                                                    component={renderSearchSelect}
                                                    loadOptions={getPersons}
                                                    className="no-border"
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="d-flex justify-content-between flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                        <div className="m-2">
                                            <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                                <label htmlFor="email" className="">Correo electrónico</label>
                                            </div>
                                            <div className=" d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                                <Field name="email" disabled={view} component={renderField} type="text" className="form-control"/>
                                            </div>
                                        </div>
                                        <div className="m-2">
                                            <div className=" d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                                <label htmlFor="phone" className="">Teléfono de línea fija</label>
                                            </div>
                                            <div className=" d-flex justify-content-end flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                                <Field name="phone" disabled={view} component={renderField} type="text" className="form-control"/>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="mb-md-4 d-flex justify-content-between flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                        <div className="m-2">
                                            <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">

                                                <label htmlFor="cellphone" className="">Teléfono celular</label>
                                            </div>
                                            <div className=" d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                                <Field name="cellphone" disabled={view} component={renderField} type="text" className="form-control"/>
                                            </div>
                                        </div>
                                        <div className="m-2">
                                            <div className=" d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                                <label htmlFor="referred_by" className="">Referente</label>
                                            </div>
                                            <div className=" d-flex justify-content-end flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                                <Field
                                                    disabled={view}
                                                    name="referred_by"
                                                    valueKey="value"
                                                    labelKey="label"
                                                    label="name"
                                                    component={renderSearchSelect}
                                                    loadOptions={getPersons}
                                                    className="no-border"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </React.Fragment>
                            }
                        </React.Fragment>
                */}

                {props.referente !== null && props.referente !== undefined &&
                <React.Fragment>
                <div className="mt-3 mx-2 mb-4 row-fluid d-flex justify-content-center flex-column flex-sm-row align-items-stretch align-items-sm-center">
                    <div className="patient-label mr-md-3">
                        <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                            <label className="m-0">CARGAR PRODUCTOS</label>
                        </div>
                    </div>
                    <div className="line-patient mr-3 d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center w-100">
                        {/* This empty div is important to show the line among each category-prices row  */}
                    </div>
                    <div onClick={showProducts} className={`${load_products == true ? 'patient-up-icon' : 'patient-down-icon'} my-2`}>
                        <div>
                        </div>
                    </div>
                </div>
                {
                    load_products ?
                    <React.Fragment>
                        <table className="table table-bordered">
                            <thead>
                                <tr className="table-secondary">
                                    <th className="text-black">Servicio</th>
                                    <th className="text-black">Cantidad</th>
                                    <th className="text-black">Acciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                 services_list.map(element => (
                                     <tr key={element.id}>
                                        <td>{element.name}</td>
                                        <td>
                                            <div className="group-button-up-down">
                                                <button type="button" className="button-content left-tab" onClick={() => {props.upDownCant(element.id, false)}}>
                                                    <img className="card-img-top d-block arrow-button" id="arrow-button-toggle" src={require("../../../../assets/img/icons/down-arrow-white-icon.png")} alt="CashBox image" />
                                                </button>
                                                {/*<span className="input-cant text-left" style={{width:"50px",textAlign:"center"}}>{element.quantity}</span>*/}
                                                <input
                                                    className='input-cant text-left'
                                                    type="number"
                                                    value={element.quantity}
                                                    onChange={(e)=>{
                                                        const valor = e.target.value === '' ? 0 : parseFloat(e.target.value);
                                                        props.setCant(element.id, valor);
                                                    }}
                                                />
                                                <button type="button" className="button-content right-tab" onClick={() => {props.upDownCant(element.id, true)}}>
                                                    <img className="card-img-top d-block arrow-button" id="arrow-button-toggle" src={require("../../../../assets/img/icons/up-arrow-white-icon.png")} alt="CashBox image" />
                                                </button>
                                            </div>
                                        </td>
                                        <td>
                                            <a onClick={() => props.removeServiceToList(element.id)}> <img className="action-img delete" src={require("../../../../assets/img/icons/delete-icon.png")} alt="eliminar" /></a>
                                        </td>
                                     </tr>
                                 ))
                                }
                                <tr>
                                    <td colSpan={2}></td>
                                    <td>
                                        <MyModal isOpen={props.modal_service}
                                            title="Agregar Producto"
                                            formId="form_surgery_services"
                                            imageButton={<img className="action-img back-green" src={require("../../../../assets/img/icons/add-icon.png")} alt="Agregar" />}
                                            classToggleButton="d-block h-100 button-service-icon"
                                            aceptar={props.addServiceToList}
                                            body={
                                                <React.Fragment>

                                                <ServiceForm
                                                    //onSubmit={props.addServiceToList}
                                                    onSubmit={()=>{console.log(' --- ')}}
                                                    data_service={props.data_service}
                                                    page_service={props.page_service}
                                                    cargando_services={props.cargando_services}
                                                    listarServicios={props.listarServicios}
                                                    addServiceToList={props.addServiceToList}
                                                    searchService={props.searchService}
                                                    buscador_service={props.buscador_service}
                                                    selected_referent={props.referente}
                                                    //addServiceToListDiff={addServiceToListDiff}
                                                    addServiceToListDiff={props.addServiceToListDiff}
                                                />

                                                </React.Fragment>
                                            }
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </React.Fragment>
                    :
                    null
                }
                </React.Fragment>
                }


                <div className='d-flex flex-row ml-2'>
                    <label className="radio-inline mr-3 d-flex align-items-center">
                        OD&nbsp;
                        <Field
                            name="eye"
                            component={renderField}
                            type="radio"
                            value={"derecho"}
                            disabled={view}
                        />
                    </label>
                    <label className="radio-inline mr-3 d-flex align-items-center">
                        OS&nbsp;
                        <Field
                            name="eye"
                            component={renderField}
                            type="radio"
                            value={"izquierdo"}
                            disabled={view}
                        />
                    </label>
                    <label className="radio-inline mr-3 d-flex align-items-center">
                        OU&nbsp;
                        <Field
                            name="eye"
                            component={renderField}
                            type="radio"
                            value={"ambos"}
                            disabled={view}
                        />
                    </label>
                    <div className='d-flex flex-row ml-auto'>
                        <div>
                            <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                                <label htmlFor="minimal existence" className="m-0">Alquiler o Cirugía</label>
                            </div>
                            <div className='d-flex flex-row mr-2'>
                                <div style={{marginRight:"10px"}}>
                                    <Field
                                        name="is_surgery"
                                        component={renderSwitch}
                                        className="form-control"
                                        disabled={view}
                                        style={{marginRight:"10px"}}
                                    />
                                </div>
                                <div>
                                    {(props.is_surgery) ? 'CIRUGÍA' : "ALQUILER"}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="mb-md-4 d-flex justify-content-between flex-column flex-sm-row align-items-stretch align-items-sm-center">
                    <div className="m-2 d-flex flex-column w-100">
                        <div className="d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                            <label htmlFor="annotations">Anotaciones</label>
                        </div>
                        <div className="">
                            <Field
                                name="annotations"
                                disabled={view}
                                component={modalTextArea}
                                className="annotations w-100"
                            />
                        </div>
                    </div>
                </div>
                
                <div 
                    className="mb-md-4 d-flex justify-content-between flex-column flex-sm-row align-items-stretch align-items-sm-center"
                >
                    <div className="m-2">
                        <div className=" d-flex justify-content-start flex-column flex-sm-row align-items-stretch align-items-sm-center">
                            <label htmlFor="referred_by" className="">Enviar SMS</label>
                        </div>
                        <div className=" d-flex justify-content-center  flex-column flex-sm-row align-items-stretch align-items-sm-center"
                        >
                            <Field name="send_sms" disabled={view} component={renderSwitch} className="form-control"/>
                        </div>
                    </div>
                </div>
                 
                <div className="row-fluid">
                    <div className="d-flex justify-content-center flex-column flex-sm-row align-items-stretch align-items-sm-center">
                    <Link className="btn btn-secondary btn-sm m-3" onClick={() => {if(setUpdateData) setUpdateData({})}} to="/surgeries-schedule">{!view ? ("Cancelar") : ("Regresar")}</Link>
                    {!view && (<button type="submit" onClick={() => {if(setUpdateData) setUpdateData({})}} className="btn btn-primary btn-sm m-3">Guardar</button>)}
                    {props.surgery_id &&
                        <Link className="btn btn-success btn-sm m-3" to={"/perform-surgery/" + props.surgery_id}>Pasar a cirugía</Link>
                    }
                    </div>
                </div>
            </div>
        </form>
    )
};

let SurgeryForm = reduxForm({
    form: 'surgery',
    validate,
    /*
    enableReinitialize: true,
    initialValues: {
        send_sms: true
    },
    */
})(Form);

const selector = formValueSelector('surgery');

SurgeryForm = connect(state => {
    const referente = selector(state, "referent");
    const start = selector(state, "start");
    return {
        referente,
        start,
    };

})(SurgeryForm);

export default SurgeryForm;
